.recommendedChoices {
    display: flex;
    justify-content: center;
    gap: var(--space-32);
}

.choice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: var(--space-24);
    gap: var(--space-16);
    border: 1px solid var(--color-deprecated-paper-darker);
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
}

.choice:hover {
    border-color: var(--color-deprecated-lime);
    color: var(--color-deprecated-lime);
}

.choiceFlag {
    width: auto;
    height: var(--space-64);
}

.otherChoicesSeparator {
    margin: var(--space-56) 0;
}

.selectWithButton {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    max-width: 576px;
}

.selectWrapper {
    width: 100%;
    margin-bottom: var(--space-16);
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

@media (min-width: 576px) {
    .selectWithButton {
        flex-wrap: nowrap;
    }

    .selectWrapper {
        margin-right: var(--space-16);
        margin-bottom: 0;
    }

    .buttonWrapper {
        flex-shrink: 0;
        justify-content: flex-end;
        width: auto;
    }
}
