.header {
    position: relative;
    height: var(--header-height);
    padding: var(--space-32) var(--space-16);
    overflow: hidden;
    color: var(--color-neutral-0);
}

.coverPicture {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: var(--header-height);
    inset: 0;
}

.backButton {
    color: var(--color-neutral-0);
}

.title {
    margin-bottom: var(--space-16);
}
