.agencyContainer {
    display: none;
}

.agencyModalBody {
    margin: calc(-1 * var(--space-16));
}

@media (min-width: 768px) {
    .title {
        z-index: 1;
        position: sticky;
        top: 0;
    }

    .agencyContainer {
        display: block;
        height: 100%;
        padding: 0 var(--space-24) var(--space-32);
        background-color: var(--color-primary-main);
    }
}
