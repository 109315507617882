.titleContainer {
    background-color: var(--color-primary-main);
}

.messageContainer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: var(--space-24) var(--space-8);
    gap: var(--space-4);
    color: var(--color-secondary-main);
    text-align: center;
}

.messagePicture {
    width: 72px;
    height: 72px;
}

.agentContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: var(--space-8) var(--space-16);
    gap: var(--space-16);
    color: var(--color-text-overlay);
}

.agentDetails {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
}

.agentPicture {
    width: 56px;
    height: 56px;
}

button.discoverButton {
    padding: 0;
    color: var(--color-text-overlay);
}

@media (min-width: 768px) {
    .discoverButton {
        display: none;
    }

    .agentContainer {
        padding: var(--space-32);
        padding-bottom: 0;
    }

    .agentPicture {
        width: 80px;
        height: 80px;
    }
}
